<template>
  <div class="page-main">
    <div class="login-box">
      <div class="login-name">钢材伙伴</div>
      <div class="login-cont">
        <div class="login-title">用户登录</div>
        <el-form class="login_form" :rules="rules" :model="loginForm" ref="refForm">
          <el-form-item prop="user_name">
            <el-input
              prefix-icon="el-icon-user"
              placeholder="请输入手机号"
              v-model="loginForm.user_name"
            />
          </el-form-item>
          <el-form-item prop="pass_word">
            <el-input
              type="password"
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              v-model="loginForm.pass_word"
              @keyup.enter.native="login"
            />
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loginForm: {
        user_name: '',
        pass_word: '',
        isWxLogin: false,
        code: ''
      },
      // 表单验证规则
      rules: {
        user_name: [{ required: true, message: '请输入手机号' }],
        pass_word: [
          {
            required: true,
            message: '请输入密码'
          },
          {
            min: 6,
            max: 15,
            message: '密码长度在6~15个字符'
          }
        ]
      }
    }
  },
  methods: {
    login() {
      // 点击登录的时候先调用validate方法验证表单内容是否有误
      this.$refs.refForm.validate(async (valid) => {
        // 如果valid参数为true则验证通过
        if (!valid) {
          return
        }
        // 发送请求进行登录
        this.$api({
          method: 'post',
          url: '/admin/doLogin',
          data: {
            loginForm: this.loginForm
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('登录成功')
              // 保存token
              localStorage.setItem('sessionId', res.data.sessionId)
              localStorage.setItem('runTimeTest', res.data.runTimeTest)
              this.$store.dispatch('asyncUpdateRunTimeTest', res.data.runTimeTest)
              this.$store.dispatch('asyncUpdateLoginAccount', res.data.loginAccount)
              this.$router.push('/')
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch(() => {
            this.$message.error('网络超时，请重试！')
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  position: relative;
  height: 100%;
  background: @colorShallowBlue url(https://gcvip168.com/wx/pc/login-bg.png) no-repeat;
  background-size: 100% 100%;
  .login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    .login-name {
      margin-bottom: 20px;
      text-align: center;
      font-size: 16px;
      line-height: 50px;
      color: @colorWhite;
      background: #474c57;
    }
    .login-cont {
      padding: 0 20px;
      margin-top: 60px;
      .login-title {
        margin-bottom: 10px;
        font-size: 14px;
        color: @colorGray;
      }
      .login-btn {
        width: 100%;
      }
      /deep/ .el-input__inner {
        padding-left: 30px;
      }
      .el-form-item ~ .el-form-item {
        margin-top: 20px;
      }
    }
  }
}
</style>
